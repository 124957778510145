import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RootRoute() {
  return (
    <div className="container">
      <Outlet />
      <ToastContainer
        position="top-center"
        autoClose={4000}
        closeOnClick
        pauseOnFocusLoss={false}
        rtl={false}
        theme="light" />
    </div>
  )
}