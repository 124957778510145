import CaptnButton from "../components/shared/CaptnButton";
import CreateTeam from "../components/Teams/Create";
import ShowAllTeams from "../components/Teams/ShowAll";

export default function TeamsRoute() {
  return (
    <>
      <ShowAllTeams />
      <CreateTeam />
      <CaptnButton link='/'>Home</CaptnButton>
    </>
  )
}