import { useState } from "react";
import CaptnButton from "../components/shared/CaptnButton";
import CreateForm from "../components/Users/Create";
import LoginForm from "../components/Users/Login";

export default function LoginScreen() {
  const [showLogin, setShowLogin] = useState(false);

  const toggleLoginSignup = () => {
    setShowLogin(!showLogin)
  }
  return (
    <div className="App">
      {showLogin && (
        <div className="vertical">
          <LoginForm />
          <CaptnButton handleSubmit={toggleLoginSignup}>Need an account? Sign up here!</CaptnButton>
        </div>
      )}
      {!showLogin && (
        <div className="vertical">
          <CreateForm />
          <CaptnButton handleSubmit={toggleLoginSignup}>Already have an account? Login here!</CaptnButton>
        </div>
      )}
    </div>
  )
}