import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { userApi } from '../features/user/userQuery';
import counterReducer from '../features/counter/counterSlice';
import userReducer from '../features/user/userSlice';
import { teamApi } from '../features/teamsQueries';
import { teamMemberApi } from '../features/teamMembersQueries';
import { gameApi } from '../features/gamesQueries';
import { emptySplitApi } from '../features/emptySplitApi';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    [userApi.reducerPath]: userApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [teamMemberApi.reducerPath]: teamMemberApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([emptySplitApi.middleware])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
