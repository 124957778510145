import { useState } from 'react';
import CaptnButton from '../shared/CaptnButton';
import CaptnTextInput from '../shared/CaptnTextInput';
import { storeData } from '../../features/localStorage';
import { toast } from 'react-toastify';
import { useLoginUserMutation } from '../../features/user/userQuery';
import { HandleResponse } from '../../features/emptySplitApi';

export default function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [resetPasswordFlow, setResetPasswordFlow] = useState(false);
  const [newPasswordFlow, setNewPasswordFlow] = useState(false);

  const [loginUser] = useLoginUserMutation();
  const login = () => {
    const userData = { user: { email: email, password: password } }
    loginUser(userData).then((response) => {
      HandleResponse(response, "Welcome back to Captn!");
      console.log(response)
      if ('data' in response) {
        storeData('authInfo', JSON.stringify(response.data))
        window.location.href = window.location.origin;
      }
    });
  }

  const resetPassword = () => {
    if (email === '') {
      toast.info('Please fill out email')
      return
    }

    const userData = { user: { email: email } }
    fetch(`${process.env.REACT_APP_API_URL}/forgot_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData)
    })
    toast.success('Please check your email.')
    setResetPasswordFlow(false)
    setNewPasswordFlow(true)
  }

  const confirmNewPassword = () => {
    const userData = {
      user: {
        email,
        password_reset: resetCode,
        password, 
        password_confirmation: passwordConfirmation,
      }
    }

    fetch(`${process.env.REACT_APP_API_URL}/reset_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData)
    })
    .then((response) => response.json())
    .then((json) => {
      // SORRRRRRY. I need to probably see if I can get this working with the redux mutations
      // But for now, we need to see if data is in our request, and if it is, then 
      if ('id' in json) {
        toast.success('Password successfully reset')
        setNewPasswordFlow(false) 
        setPassword('')
      } else {
        // Else we almost certainly have been returned an error
        toast.error(json.join(`.\n`))
      }
    })
  }

  if (resetPasswordFlow) {
    return (
      <>
        <CaptnTextInput placeholder="Email" type='email' onChangeText={(newEmail) => (setEmail(newEmail))} value={email} />
        <CaptnButton primary={true} handleSubmit={resetPassword}>Reset password</CaptnButton>
      </>
    )
  }

  if (newPasswordFlow) {
    return (
      <>
        <CaptnTextInput placeholder="Code" onChangeText={(newVal) => (setResetCode(newVal))} value={resetCode} />
        <CaptnTextInput placeholder="Password" type='password' autoComplete='password' onChangeText={(newVal) => (setPassword(newVal))} value={password} />
        <CaptnTextInput placeholder="Confirm" type='password' autoComplete='password' onChangeText={(newVal) => (setPasswordConfirmation(newVal))} value={passwordConfirmation} />
        <CaptnButton primary={true} handleSubmit={confirmNewPassword}>Reset password</CaptnButton>
      </>
    )
  }
  return (
    <>
      <CaptnTextInput placeholder="Email" type='email' onChangeText={(newEmail) => (setEmail(newEmail))} value={email} />
      <CaptnTextInput placeholder="Password" type='password' autoComplete='password' onChangeText={(newPassword) => { setPassword(newPassword) }} value={password} />
      <CaptnButton primary={true} handleSubmit={login}>Login</CaptnButton>
      <CaptnButton primary={true} handleSubmit={() => setResetPasswordFlow(true)}>Forgot Password?</CaptnButton>
    </>
  );
}