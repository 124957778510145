import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewLocationMutation } from "../../features/locationsQueries";
import CaptnButton from "../shared/CaptnButton";
import CaptnTextInput from "../shared/CaptnTextInput";

export default function CreateLocation() {
  const [name, setName] = useState('');
  const [zip, setZip] = useState('')
  const [address, setAddress] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('United States')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [city, setCity] = useState('')
  const [stripeAccountId, setStripeAccountId] = useState('')
  const [createLocation] = useAddNewLocationMutation();
  const handleSubmit = () => {
    const locationData = {
      location: {
        name,
        zip,
        address,
        state,
        country,
        phone_number: phoneNumber,
        city,
      }
    }
    createLocation(locationData).then((response) => {
      HandleResponse(response, 'Location created!');
    });
    setName('')
  }

  return (
    <div className="vertical">
      <CaptnTextInput onChangeText={(newName) => { setName(newName) }} value={name} placeholder="Name" />
      <CaptnTextInput onChangeText={(newAddress) => { setAddress(newAddress)}} value={address} placeholder="address" />
      <CaptnTextInput onChangeText={(newCity) => { setCity(newCity)}} value={city} placeholder="city" />
      <CaptnTextInput onChangeText={(newState) => { setState(newState)}} value={state} placeholder="state" />
      <CaptnTextInput onChangeText={(newZip) => { setZip(newZip)}} value={zip} placeholder="zip" />
      <CaptnTextInput onChangeText={(newCountry) => { setCountry(newCountry)}} value={country} placeholder="country" />
      <CaptnTextInput onChangeText={(newPhoneNumber) => { setPhoneNumber(newPhoneNumber)}} value={phoneNumber} placeholder="Phone Number" />
      <CaptnTextInput onChangeText={(newStripeAccountId) => { setStripeAccountId(newStripeAccountId)}} value={stripeAccountId} placeholder="stripeAccountId" />
      <CaptnButton primary handleSubmit={handleSubmit}>Create Location</CaptnButton>
    </div>
  )
}

// Type '{ location: { name: string; coed: number; Location_color: string; }; }' has no properties in common with type 
// 'UseMutationStateOptions<MutationDefinition<{ location: { name: string; color: string; gender: string; }; }, BaseQueryFn
