import React, { useState } from 'react';
import DateSelector from '../shared/CaptnDateSelector'
import moment from 'moment';
import { useAddNewGameMutation, useUpdateGameMutation } from '../../features/gamesQueries';
import { Team } from '../../models/Team';
import { Game } from '../../models/Game';
import CaptnTextInput from '../shared/CaptnTextInput';
import CaptnPicker from '../shared/CaptnPicker';
import CaptnButton from '../shared/CaptnButton';
import { HandleResponse } from '../../features/emptySplitApi';

export type Props = {
  team: Team,
  game?: Game,
  stopEditing?: () => void
}

export interface GamePartial {
  location: string,
  game_date_time: string,
  game_type: string,
  home_team_id: number,
}

export default function CreateGame(props: Props) {
  const team = props.team;
  const game = props.game;
  const initialLocation = game == null ? '' : game.location;
  const initialGameType = game == null ? 'season' : game.gameType;
  const initialGameDate = game == null ? moment() : moment(game.gameDateTime);
  const buttonText = game == null ? 'Create Game' : 'Update Game';

  const [location, setLocation] = useState(initialLocation);
  const [gameType, setGameType] = useState(initialGameType);
  const [createGame] = useAddNewGameMutation();
  const [updateGame] = useUpdateGameMutation();
  const [gameDateTime, setDate] = useState(initialGameDate);
  const handleSubmit = () => {
    const gameData: GamePartial = {
      location,
      game_type: gameType,
      game_date_time: gameDateTime.format(),
      home_team_id: team.id // Gonna need to mess with this concept if/when league games come out
    }
    let wasSuccess = false
    if (game == null) {
      createGame({ 'game': gameData }).then((response) => {
        wasSuccess = HandleResponse(response, 'Game created')

        // Quality of life, most teams using this have a game the same day each week.
        if (wasSuccess) setDate(gameDateTime.add(7, 'days'))
      });
    } else {
      updateGame({
        id: game.id,
        ...gameData
      }).then((response) => {
        wasSuccess = HandleResponse(response, 'Game updated')
        if (wasSuccess && props.stopEditing) props.stopEditing();
      });
    }
  }

  const options = {
    'Season': 'season',
    'Playoffs': 'playoff',
    'Bye': 'bye',
  }
  return (
    <div className="vertical">
      <p>Enter Details</p>
      <CaptnTextInput placeholder="Location" onChangeText={(newLocation) => (setLocation(newLocation))} value={location} />
      <CaptnPicker value={gameType} options={options} handleChange={(itemValue) => setGameType(itemValue)} />
      <DateSelector dateTime={gameDateTime} onChangeDate={(date) => setDate(moment(date))} includeTime />
      <CaptnButton primary handleSubmit={handleSubmit}>{buttonText}</CaptnButton>
    </div>
  )
}