export type Props = {
  options: { [key: string]: any },
  value: string,
  handleChange: (selectedValue: string) => void
}

export default function CaptnPicker(props: Props) {
  const handleChange = (event: any) => {
    props.handleChange(event.target.value)
  }
  const pickerItems = () => {
    return Object.keys(props.options).map((key) => {
      return <option key={key} value={props.options[key]}>{key}</option>
    });
  }

  return (
    <select className="captnPicker" value={props.value} onChange={(e) => handleChange(e)}>
      {pickerItems()}
    </select>
  )
}