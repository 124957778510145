import { toast } from 'react-toastify';

enum ErrorLevel {
  info,
  error,
  success,
  warning,
}

export type Props = {
  content: string,
  errorLevel: keyof typeof ErrorLevel
}

export default function CreateToastAlert(props: Props) {
  switch (props.errorLevel) {
    case 'info':
      toast.info(props.content);
      break;
    case 'error':
      toast.error(props.content);
      break;
    case 'success':
      toast.success(props.content);
      break;
    case 'warning':
      toast.warning(props.content);
      break;
    default:
      break;
  }
}
