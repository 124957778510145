import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewLeagueMutation } from "../../features/locationsQueries";
import { Location } from "../../models/Location";
import CaptnButton from "../shared/CaptnButton";
import CaptnPicker from "../shared/CaptnPicker";
import CaptnTextInput from "../shared/CaptnTextInput";

type CreateProps = {
  location: Location;
};

export default function CreateLeague({ location }: CreateProps) {
  const [description, setDescription] = useState('');
  const [coed, setCoed] = useState('combined');
  const [createLeague] = useAddNewLeagueMutation();
  const handleSubmit = () => {
    const leagueData = {
      league: {
        location_id: location.id,
        coed,
        description,
      }
    }
    createLeague(leagueData).then((response) => {
      HandleResponse(response, 'League created!');
    });
    setDescription('')
  }

  const genderOptions = {
    'Male': 'male',
    'Female': 'female',
    'Combined': 'combined',
  }

  return (
    <div className="vertical">
      <CaptnTextInput onChangeText={(n) => { setDescription(n) }} value={description} placeholder="Wednesday Coed/Tuesday 30+" />
      <CaptnPicker options={genderOptions} value={coed} handleChange={(itemValue) => setCoed(itemValue)} />
      <CaptnButton primary handleSubmit={handleSubmit}>Create League</CaptnButton>
    </div>
  )
}
