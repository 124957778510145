import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomeScreen from './components/Home';
import ErrorPage from './routes/error';
import LoginScreen from './routes/login';
import RootRoute from './routes/root';
import './App.scss';
import TeamsRoute from './routes/teams';
import TeamDetailsRoute from './routes/teamDetails';
import GameDetailsRoute from './routes/gameDetails';
import LocationsRoute from './routes/locations';
import LocationDetailsRoute from './routes/locationsDetails';
import LeagueDetailsRoute from './routes/leagueDetails';
import ProfileRoute from './routes/profile';
import LeagueSessionDetailsRoute from './routes/leagueSessionDetails';
import CompetitionSessionDetailsRoute from './routes/competitionSessionDetails';
import ContactRoute from './routes/contact';
import AboutRoute from './routes/about';

const container = document.getElementById('root')!;
const root = createRoot(container);

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/",
    element: <RootRoute />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "login",
        element: <LoginScreen />,
      },
      {
        path: "profile",
        element: <ProfileRoute />,
      },
      {
        path: "teams",
        element: <TeamsRoute />,
      },
      {
        path: "teams/:teamId",
        element: <TeamDetailsRoute />,
      },
      {
        path: "game/:gameId",
        element: <GameDetailsRoute />,
      },
      {
        path: "locations",
        element: <LocationsRoute />,
      },
      {
        path: "locations/:locationId",
        element: <LocationDetailsRoute />,
      },
      {
        path: "league/:leagueId",
        element: <LeagueDetailsRoute />,
      },
      {
        path: "session/:leagueSessionId",
        element: <LeagueSessionDetailsRoute />,
      },
      {
        path: "competition-session/:competitionSessionId",
        element: <CompetitionSessionDetailsRoute />,
      },
      {
        path: "contact",
        element: <ContactRoute />,
      },
      {
        path: "about",
        element: <AboutRoute />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
