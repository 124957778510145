import { Location } from "../../models/Location";
import CardDetails from "../shared/CardDetails";

type LocationDetailProps = {
  location: Location;
};

export default function ShowLocationDetails({ location }: LocationDetailProps) {
  return (
    <CardDetails header={location.name} link={location.id} />
  )
}
