import { useState } from "react";
import { selectUser } from "../../features/user/userSlice";
import { GameAttendee } from "../../models/GameAttendee";
import { Message } from "../../models/Message";
import { TeamMember } from "../../models/TeamMember";
import { User } from "../../models/User";
import { useAppSelector } from "../../redux/hooks";
import CaptnButton from "./CaptnButton";
import CaptnTextInput from "./CaptnTextInput";

export type Recipient = User | GameAttendee | TeamMember;
type QuickMessageOption = {[key: string]: Recipient[]}
export type Props = {
  identifier: (id: number) => string,
  className?: string,
  handleSubmit: (message: Message) => void,
  availableUsers: Recipient[],
  quickMessageOptions: QuickMessageOption[],
}

export default function CaptnMessage(props: Props) {
  const { availableUsers, handleSubmit, identifier, quickMessageOptions } = props;
  const currentUser = useAppSelector(selectUser).currentUser;
  const signature = currentUser ? currentUser.firstName : '';
  const [currentMessage, setCurrentMessage] = useState('');
  const [selectedRecipients, setSelectedRecipients] = useState([] as Recipient[]);
  const [availableRecipients, setAvailableRecipients] = useState(availableUsers); 

  const sendMessage = () => {
    handleSubmit({
      players: selectedRecipients.map((p) => p.id),
      message: currentMessage
    });
  }

  const toggleSelected = (recipient: Recipient) => {
    const exists = selectedRecipients.includes(recipient)
    if (exists) {
      setSelectedRecipients(selectedRecipients.filter((c) => { return c !== recipient }))
      setAvailableRecipients([
        ...availableRecipients,
        recipient
      ])
    } else {
      setAvailableRecipients(availableRecipients.filter((c) => { return c !== recipient }))
      setSelectedRecipients([
        ...selectedRecipients,
        recipient
      ])
    }
  }
  const toggleRecipients = (recipients: Recipient[]) => {
    setSelectedRecipients(recipients)
    setAvailableRecipients(availableUsers.filter(a => !recipients.includes(a)))
  }

  const renderRecipientList = (recipients: Recipient[]) => {
    return (<ul>
      {recipients.map(recipient => {
        return <li className="pointer" onClick={() => toggleSelected(recipient)} key={recipient.id}>{identifier(recipient.id)}</li>
      })}
    </ul>)
  }

  const sendIsDisabled = () => {
    return (selectedRecipients && selectedRecipients.length === 0) || currentMessage === '';
  }

  const renderQuickOptions = () => {
    return (
      quickMessageOptions.map((dict, index) => {
        const title = Object.keys(dict)[0];
        const recipients = dict[title];
        return <CaptnButton key={title} handleSubmit={() => toggleRecipients(recipients)}>{title}</CaptnButton>
      })
    )
  }

  return (
    <>
      <div className="card">
        <p className="small">Note: This message will only send to registered users.</p>
        <div className="horizontal">
          {renderQuickOptions()}
          <CaptnButton handleSubmit={() => toggleRecipients([])}>Clear</CaptnButton>
        </div>
        <hr />
        <div className="card">
          <p>Sending To</p>
          {renderRecipientList(selectedRecipients)}
        </div>
        <div className="card">
          <p>Available Members</p>
          {renderRecipientList(availableRecipients)}
        </div>
        <div>
          <CaptnTextInput textarea value={currentMessage} onChangeText={(t) => setCurrentMessage(t)} />
          <p className="small">Note: This message will end with the signature <br /> <i>- {signature}</i></p>
          <CaptnButton primary handleSubmit={sendMessage} disabled={sendIsDisabled()}>Send</CaptnButton>
        </div>
      </div>
    </>
  )
}
