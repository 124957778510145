import { useParams } from "react-router-dom";
import CreateLeagueSession from "../components/LeagueSessions/Create";
import CaptnButton from "../components/shared/CaptnButton";
import { useGetLeagueByIdQuery } from "../features/leaguesQueries";
import { selectUser } from "../features/user/userSlice";
import { useAppSelector } from "../redux/hooks";

export default function LeagueDetailsRoute() {
  let { leagueId } = useParams<{ leagueId: string }>();
  //TODO: There HAS to be a better way to convince typescript that we got here with a leagueId, it won't be null, I promise
  leagueId = !leagueId ? '' : leagueId;

  const { data, isLoading, isError } = useGetLeagueByIdQuery(leagueId)

  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data) return (<p>This league somehow does not exist</p>)

  const renderSessions = () => {
    if (data.leagueSessions) {
      return (
        <>
          {data.leagueSessions.map(la => { return (<p key={`league-${la.id}`}><CaptnButton link={`/session/${la.id}`}>{la.description}</CaptnButton></p>) } )}
        </>
      )
    }
    return <p>No sessions at this time</p>
  }

  return (
    <>
      <p>Available Sessions</p>
      {renderSessions()}

      <CaptnButton link='/'>Home</CaptnButton>


      {/* (isSiteAdmin || data.isLeagueAdmin) */}
      { data.isLeagueAdmin && (
        <>
          <p>Add a session</p>
          <p className="small text-center">This is something that will change throughout the year. Something like seasons, Fall/Summer/One off Tournament, competition level is next.</p>
          <CreateLeagueSession league={data} />
        </>
      )}
    </>
  )
}