import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { League } from "../../models/League";
import CaptnButton from "../shared/CaptnButton";
import CaptnTextInput from "../shared/CaptnTextInput";
import DateSelector from "../shared/CaptnDateSelector";
import moment from "moment";
import CaptnCheckbox from "../shared/CaptnCheckbox";
import { LeagueSessionPartial } from "../../models/LeagueSession";
import { useAddNewLeagueSessionMutation } from "../../features/leaguesQueries";

type CreateProps = {
  league: League;
};

export default function CreateLeagueSession({ league }: CreateProps) {
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [cost, setCost] = useState(500.00);
  const [openRegistrationStart, setOpenRegistrationStart] = useState(moment());
  const [openRegistrationEnd, setOpenRegistrationEnd] = useState(moment());
  const [hasPostSeason, setHasPostSeason] = useState(true);
  const [maxTeams, setMaxTeams] = useState(8);
  const [numberOfGames, setNumberOfGames] = useState(8);
  const [createLeagueSession] = useAddNewLeagueSessionMutation();
  const handleSubmit = () => {
    const leagueSession: LeagueSessionPartial = {
      league_id: league.id,
      cost: cost,
      start_date: startDate.format(),
      open_registration_start: openRegistrationStart.format(),
      open_registration_end: openRegistrationEnd.format(),
      has_post_season: hasPostSeason,
      max_teams: maxTeams,
      description: description,
      number_of_games: numberOfGames,
    }
    console.log(leagueSession)
    createLeagueSession({ league_session: leagueSession }).then((response) => {
      HandleResponse(response, 'LeagueSession created!');
    });
    // setDescription('')
  }

  return (
    <div className="vertical">
      <CaptnTextInput onChangeText={(n) => { setDescription(n) }} value={description} placeholder="A/B/C" label='Description' />
      <CaptnTextInput onChangeText={(n) => { setCost(parseFloat(n)) }} value={cost} type='number' decimal label='Cost ($)' />
      <CaptnCheckbox onChangeText={(n) => { setHasPostSeason(n) }} value={hasPostSeason} label='Has Post Season' />
      <CaptnTextInput onChangeText={(n) => { setMaxTeams(parseInt(n)) }} value={maxTeams} type='number' placeholder='8' label='Max Teams' />
      <CaptnTextInput onChangeText={(n) => { setNumberOfGames(parseInt(n)) }} value={numberOfGames} type='number' placeholder='8' label='Number of Games' />
      <DateSelector onChangeDate={(n) => { setStartDate(moment(n)) }} dateTime={startDate} label='Start Date' />
      <DateSelector onChangeDate={(n) => { setOpenRegistrationStart(moment(n)) }} dateTime={openRegistrationStart} label='Open Registration Start' />
      <DateSelector onChangeDate={(n) => { setOpenRegistrationEnd(moment(n)) }} dateTime={openRegistrationEnd} label='Open Registration End' />
      <CaptnButton primary handleSubmit={handleSubmit}>Create LeagueSession</CaptnButton>
    </div>
  )
}
