import moment from 'moment';
import React from 'react';
import { Game } from '../../models/Game';
import { TeamMember } from '../../models/TeamMember';
import CaptnButton from '../shared/CaptnButton';

export type Props = {
  showTeamName: boolean,
  game: Game,
  subs: TeamMember[]
}

export default function ShowGame(props: Props) {
  const showTeamName = props.showTeamName;
  const game = props.game;
  const gameIsBye = game.gameType === 'bye';
  const renderDetailsLink = () => {
    return (
      <CaptnButton link={`/game/${game.id}`}>Details</CaptnButton>
    )
  }
  const renderTeamName = () => {
    // TODO? Some of our api responses don't include the team details
    // TODO!: Deal with home/away concept
    const teamName = game.homeTeam ? game.homeTeam.name : '';
    if (teamName) {
      return (
        <div className='teamName'>{game.homeTeam.name}</div>
      )
    }
    return null;
  }
  const renderGameDateTime = () => {
    if (gameIsBye) {
      return (
        <p className='flex-grow-2'>Bye</p>
      )
    }
    return (
      <div className='flex-grow-2'>
        {game.location}
        <br />
        {moment(game.gameDateTime).format('LT')}
      </div>
    )
  }

  return (
    <div className='card text-center showGame'>
      {showTeamName && renderTeamName()}
      <div className='horizontal'>
        <p className='gameDate'>{moment(game.gameDateTime).format('ll')}</p>
        {renderGameDateTime()}
        {renderDetailsLink()}
      </div>
    </div>
  )
}
