import { useState } from "react";
import { useParams } from "react-router-dom";
import CreateGame from "../components/Games/Create";
import ShowGame from "../components/Games/Show";
import CaptnButton from "../components/shared/CaptnButton";
import CreateTeamMember from "../components/TeamMembers/Create";
import ShowTeamMember from "../components/TeamMembers/Show";
import { useGetTeamByIdQuery } from "../features/teamsQueries";
import { TeamMember } from "../models/TeamMember";

export default function TeamDetailsRoute() {
  const [showRoster, setShowRoster] = useState(false);
  const [showSchedule, setShowSchedule] = useState(true);
  const [rosterView, setRosterView] = useState('captains');

  const toggleRosterView = () => {
    setShowRoster(!showRoster)
  }
  const toggleScheduleView = () => {
    setShowSchedule(!showSchedule)
  }

  let { teamId } = useParams<{ teamId: string }>();
  //TODO: There HAS to be a better way to convince typescript that we got here with a teamId, it won't be null, I promise
  teamId = !teamId ? '' : teamId;
  const { data, isLoading, isError } = useGetTeamByIdQuery(teamId)

  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data) return (<p>You are not part of any teams</p>)

  const captains = data.teamMember.filter(member => { return member.role === 'captain' })
  const players = data.teamMember.filter(member => { return member.role === 'player' })
  const subs = data.teamMember.filter(member => { return member.role === 'sub' })

  const renderFlatList = (teamMembers: TeamMember[]) => {
    if (teamMembers.length > 0) {
      return teamMembers.map(teamMember => {
        return (
          <div key={teamMember.id} className='card ShowTeamMember'>
            <ShowTeamMember teamMember={teamMember} isCaptain={data.userIsCaptain} />
          </div>
        )
      });
    }

    const emptyString = data.userIsCaptain ? 'No members with this role. Add some below!' : 'No members with this role';
    return (<p>{emptyString}</p>)
  }
  const renderGames = () => {
    if (data.games.length > 0) {
      return data.games.map(game => {
        return <ShowGame key={game.id} game={game} subs={subs} showTeamName={false} />
      })
    }
  }

  return (
    <div className="teamDetailsRoute">
      <h2>{data.name}</h2>
      <p>Color: {data.teamColor}</p>
      {data.userIsOnTeam && (
        <>
          {!showRoster && (
            <h3>Roster <CaptnButton handleSubmit={toggleRosterView}><i>show</i></CaptnButton></h3>
          )}
          {showRoster && (
            <>
              <h3>Roster <CaptnButton handleSubmit={toggleRosterView}><i>hide</i></CaptnButton></h3>
              <div className="horizontal">
                <CaptnButton handleSubmit={() => setRosterView('captains')}><i>Captains</i></CaptnButton>
                <CaptnButton handleSubmit={() => setRosterView('players')}><i>Players</i></CaptnButton>
                <CaptnButton handleSubmit={() => setRosterView('subs')}><i>Subs</i></CaptnButton>
              </div>
              {captains && rosterView == 'captains' && (
                <>
                  <h4>Captains</h4>
                  {renderFlatList(captains)}
                </>
              )}
              {players && rosterView == 'players' && (
                <div>
                  <h4>Players</h4>
                  {renderFlatList(players)}
                </div>
              )}
              {subs && rosterView == 'subs' && (
                <div>
                  <h4>Subs</h4>
                  {renderFlatList(subs)}
                </div>
              )}
            </>
          )}
        </>
      )}
      <>
        {!showSchedule && (
          <h3>Schedule <CaptnButton handleSubmit={toggleScheduleView}><i>show</i></CaptnButton></h3>
        )}
        {showSchedule && (
          <>
            <h3>Schedule <CaptnButton handleSubmit={toggleScheduleView}><i>hide</i></CaptnButton></h3>
            {data.games.length === 0 && (
              <p>No upcoming games</p>
            )}
            {data.games.length > 0 && (
              <>
                {renderGames()}
              </>
            )}
          </>
        )}
      </>
      {data.userIsCaptain && (
        <div className="vertical">
          <p>Add New Team Member</p>
          <CreateTeamMember teamId={teamId} />
          <p>Add New Game</p>
          <CreateGame team={data} />
        </div>
      )}
      <div className="horizontal">
        <CaptnButton link='/'>Home</CaptnButton>
        <CaptnButton link='/teams'>Teams</CaptnButton>
      </div>
    </div>

  )
}