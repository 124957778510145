import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../redux/store';
import { User } from '../../models/User';
import { getData, removeItem, storeData } from '../localStorage';

const setInitial = (key: string) => {
  const data = getData(key);
  if (data) {
    return JSON.parse(data)
  }

  return {}
}

// Define a type for the slice state
interface UserState {
  authInfo: {
    token: string,
    email: string,
    userId: string
  },
  currentUser?: User
}

// Define the initial state using that type
const initialState: UserState = {
  authInfo: setInitial('authInfo'),
  currentUser: setInitial('currentUser')
}

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAuthState: (state, value) => {
      state.authInfo = value.payload;
    },
    setAuthFromStorage: (state, value) => {
      state.authInfo = value.payload;
      storeData('authInfo', JSON.stringify(state.authInfo))
    },
    setCurrentUser: (state, value) => {
      state.currentUser = value.payload;
      console.log(value)
      debugger;
      storeData('currentUser', JSON.stringify(state.currentUser))
    },
    removeAuthFromStorage: (state) => {
      // state.authInfo = undefined;
      console.log('test')
      removeItem('authInfo')
    }
  }
})

export const { removeAuthFromStorage, setAuthFromStorage, setAuthState, setCurrentUser } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user

export default userSlice.reducer