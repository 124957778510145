import "flatpickr/dist/themes/material_blue.css";
import moment, { Moment } from 'moment';
import Flatpickr from "react-flatpickr";
export type Props = {
  dateTime: Moment,
  label?: string,
  includeTime?: boolean,
  onChangeDate: (date: Date) => void
}
export default function DateSelector(props: Props) {
  const dateTime = props.dateTime;
  const format = props.includeTime ? 'Y-m-d h:i K' : 'Y-m-d';
  const setDate = (date: Date) => {
    props.onChangeDate(date)
  }
  return (
    <>
      {props.label && (
        <label>{props.label}</label>
      )}
      <Flatpickr
        data-enable-time={props.includeTime}
        value={moment(dateTime).format()}
        style={{ marginBottom: 10 }}
        options={
          { dateFormat: format }
        }
        onChange={(date: Date[]) => {
          setDate(date[0])
        }}
      />
    </>
  )
}