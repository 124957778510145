import moment, { Moment } from "moment";
import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewBulkGamesMutation, useAddNewScheduleMutation } from "../../features/schedulesQueries";
import { Game } from "../../models/Game";
import { LeagueSession } from "../../models/LeagueSession";
import { TeamCompetitionSession } from "../../models/TeamCompetitionSession";
import CaptnButton from "../shared/CaptnButton";
import DateSelector from "../shared/CaptnDateSelector";
import CaptnTextInput from "../shared/CaptnTextInput";

type ScheduleProps = {
  leagueSession: LeagueSession;
};

interface LeagueGamePartial {
  location: string,
  game_date_time: string,
  game_type: string,
  home_team_id: number,
  away_team_id?: number,
  league_session_id?: number,
  competition_session_id?: number,
  field: string,
  game_notes: string,
}

// export default function ShowTeamDetails({ team }: TeamDetailProps) {
export default function CreateSchedule({ leagueSession }: ScheduleProps) {
  const [field, setField] = useState('');
  const [fields, setFields] = useState([] as string[]);
  const [startTime, setStartTime] = useState('18:00');
  const [endTime, setEndTime] = useState('23:00');
  const [duration, setDuration] = useState('50');
  const [byeWeek, setByeWeek] = useState(moment());
  const [byeWeeks, setByeWeeks] = useState([] as Date[]);
  const [gameTimes, setGameTimes] = useState([] as Moment[]);
  const gamesByDateDict: { [key: string]: Game[] } = {};
  const [gamesByDate, setGamesByDate] = useState(gamesByDateDict)
  const [weekday, setWeekday] = useState(moment(leagueSession.startDate).day().toString())
  const [createSchedule] = useAddNewScheduleMutation();
  const [createGames] = useAddNewBulkGamesMutation();



  const renderCompetitionSessionDetails = () => {
    // return (<div>Tada!</div>)
    const teamComps = leagueSession.competitionSessions.filter(c => c.teamCompetitionSessions.length > 0);
    return teamComps.map(tc => {
      return (
        <div key={tc.competitionLevel}>
          <h2>{tc.competitionLevel}</h2>
          <ul>
            {renderTeams(tc.teamCompetitionSessions)}
          </ul>
        </div>
      )
    })
  }

  const renderTeams = (teamCompetitionSessions: TeamCompetitionSession[]) => {
    return teamCompetitionSessions.map(tcs => {
      return (<li key={`tcs-${tcs.id}`}>{tcs.team.name}</li>)
    })
  }

  const addField = () => {
    setFields([...fields, field])
    setField('')
  }

  const addByeWeek = () => {
    setByeWeeks([...byeWeeks, byeWeek.toDate()])
  }

  const calculateTimes = () => {
    const firstTime = moment(`${moment().format('Y-m-d')} ${startTime}`, "Y-m-d hh:mm");
    const lastTime = moment(`${moment().format('Y-m-d')} ${endTime}`, "Y-m-d hh:mm");
    let times = [firstTime];
    const nextTime = firstTime.clone();
    while (nextTime < lastTime) {
      nextTime.add(duration, 'minutes')
      times.push(nextTime.clone())
    }


    setGameTimes(times)
  }

  const schedule = () => {
    const data = {
      id: leagueSession.id,
      wday: weekday,
      fields: fields.join('|'),
      times: gameTimes.map(t => t.format('LT')).join('|'),
      byes: byeWeeks.map(t => moment(t).format('ll')).join('|'),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    createSchedule({ schedule: data }).then((response) => {
      if ('data' in response) {
        setGamesByDate(mapGamesByDate(response.data));
      } else {
        HandleResponse(response, 'We get here if we error')
      }
    })
  }

  const mapGamesByDate = (games: Game[]) => {
    var gamesDictionary: { [key: string]: Game[] } = {};
    games.forEach(game => {
      var gameDateTime = moment(game.gameDateTime).format('ll');
      if (Object.keys(gamesDictionary).indexOf(gameDateTime) === -1) {
        gamesDictionary[gameDateTime] = [] as Game[];
      }

      gamesDictionary[gameDateTime].push(game)
    });

    return gamesDictionary;
  }

  const saveNewSchedule = () => {
    let scheduleGames: LeagueGamePartial[] = [];
    Object.keys(gamesByDate).forEach(gameDateTime => {
      gamesByDate[gameDateTime].forEach(game => {
        const partialGame: LeagueGamePartial = {
          home_team_id: game.homeTeam?.id,
          away_team_id: game.awayTeam?.id,
          game_type: game.gameType,
          location: game.location,
          field: game.field,
          game_notes: game.gameNotes,
          game_date_time: moment(game.gameDateTime).format(),
          league_session_id: leagueSession.id,
          competition_session_id: game.competitionSession?.id,
        }

        scheduleGames.push(partialGame);
      });
    });
    createGames({ 'games': scheduleGames }).then((response) => {
      HandleResponse(response, `${scheduleGames.length} games created`);
    });
  }

  function dragstartHandler(ev: React.DragEvent<HTMLTableRowElement>) {
    // Add the target element's id to the data transfer object
    var target = ev.target as HTMLElement
    ev.dataTransfer.setData("application/my-app", target.id);
    ev.dataTransfer.effectAllowed = "move";
  }

  // Ok. Bare with me.
  function dropHandler(ev: React.DragEvent<HTMLTableRowElement>) {
    ev.preventDefault();
    ev.currentTarget.style.background = "";
    // We can only store strings in drag/drop, so when we drop we have the id of the game
    // and the id of the game we're dragging
    const target = ev.target as HTMLElement
    const dropTargetId = target.parentElement?.id
    const dragTargetId = ev.dataTransfer.getData("application/my-app");
    // Then we have to get the date of the game in order to pull it from the dict
    const gameDay = target.parentElement?.parentElement?.parentElement?.parentElement?.children[0]
    if (gameDay?.innerHTML) {
      const games = gamesByDate[gameDay.innerHTML]
      // Now find the index in the array of our two games to swap
      const targetGameIdx = games.findIndex((obj) => { return obj.id.toString() === dropTargetId })
      const draggingGameIdx = games.findIndex((obj) => { return obj.id.toString() === dragTargetId })

      // typescript to prove they exist...
      if (targetGameIdx > -1 && draggingGameIdx > -1) {
        // Get the games and clone them, because however we got the games in react they're read only
        const targetGameTmp = games[targetGameIdx]
        const targetGame = structuredClone(targetGameTmp)
        const draggingGameTmp = games[draggingGameIdx]
        const draggingGame = structuredClone(draggingGameTmp)
        // Swap the times and fields
        const tmpTime = targetGame.gameDateTime
        const tmpField = targetGame.field
        targetGame.gameDateTime = draggingGame.gameDateTime
        targetGame.field = draggingGame.field
        draggingGame.gameDateTime = tmpTime
        draggingGame.field = tmpField
        // Set the new games
        games[targetGameIdx] = targetGame
        games[draggingGameIdx] = draggingGame

        // Then in order for the schedule to rerender we need to create a new dictionary
        var gamesDictionary: { [key: string]: Game[] } = {};
        for (const [key, value] of Object.entries(gamesByDate)) {
          gamesDictionary[key] = value.map((game) => { return game })
        }
        setGamesByDate(gamesDictionary)
      }
    }
  }

  function dragoverHandler(ev: React.DragEvent<HTMLTableRowElement>) {
    ev.preventDefault();

    ev.currentTarget.style.background = "lightblue";
    ev.dataTransfer.dropEffect = "move";
  }

  function dragLeaveHandler(ev: React.DragEvent<HTMLTableRowElement>) {
    ev.preventDefault();

    ev.currentTarget.style.background = "";
    ev.dataTransfer.dropEffect = "move";
  }

  const renderNewGame = (game: Game) => {
    const homeName = game.homeTeam ? game.homeTeam.name : 'TBD';
    const awayName = game.awayTeam ? game.awayTeam.name : 'TBD';
    if (game.gameType === 'bye') {
      return (
        // <div className="card" key={game.id}>
        //   <>
        //     {homeName}<hr />
        //     <p>Bye</p>
        //   </>
        // </div>
        <tr><td>{homeName}: Bye</td></tr>
      )
    }
    return (
      <tr
        draggable="true"
        id={game.id.toString()}
        key={game.id}
        onDragStart={(event) => dragstartHandler(event)}
        onDrop={(event) => dropHandler(event)}
        onDragOver={(event) => dragoverHandler(event)}
        onDragLeave={(event) => dragLeaveHandler(event)}
        className="tr-border"
      >
        <td>{homeName}</td>
        <td>{awayName}</td>
        <td>{game.field}</td>
        <td>{moment(game.gameDateTime).format('LT')}</td>
      </tr>
      // <div className="card" key={game.id}>
      //   <>
      //     {homeName} vs {awayName} <hr />
      //     <p>{game.location} - {game.field}</p>
      //     <p>{moment(game.gameDateTime).format('LT')}</p>
      //     {game.gameNotes && (
      //       <p>{game.gameNotes}</p>
      //     )}
      //   </>
      // </div>
    )
  }

  const renderGameDate = (gameDate: string, games: Game[]) => {
    const sortedGames = games.sort((a, b) => (a.gameDateTime < b.gameDateTime) ? -1 : 1);
    return (
      <div key={gameDate}>
        <h3>{gameDate}</h3>
        <table className="schedule-table">
          <thead>
            <tr>
              <th>Home</th>
              <th>Away</th>
              <th>Field</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {sortedGames.map(ng => renderNewGame(ng))}
          </tbody>
        </table>
      </div>
    )
  }

  // TODO: I'm thinking we should change our gamesByDateDict concept to just be all the games,
  // then do the mapping/sorting on the render instead.
  const renderGameDates = (gameDates: typeof gamesByDateDict) => {
    const keys = Object.keys(gameDates);

    return (
      <>
        {keys.map(key => renderGameDate(key, gameDates[key]))}
      </>
    )
  }

  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeekday(event.target.value);
  };

  return (
    <>
      <p>{`Start Date: ${leagueSession.startDate}`}</p>
      <div className="horizontal">
        {renderCompetitionSessionDetails()}
      </div>
      <div className="horizontal">
        <div>
          <h4>Fields</h4>
          <div>
            {fields.map(f => (<p key={f}>{f}</p>))}
            <CaptnTextInput onChangeText={(e) => setField(e)} value={field} />
            <CaptnButton handleSubmit={addField}>Add Field</CaptnButton>
          </div>
        </div>
        <div>
          <h4>Weekday</h4>
          <p>
            <input type='radio' name='weekday' value='0' onChange={radioHandler} />
            <label htmlFor="Sunday">Sunday</label>
          </p>
          <p>
            <input type='radio' name='weekday' value='1' onChange={radioHandler} />
            <label htmlFor="Monday">Monday</label>
          </p>
          <p>
            <input type='radio' name='weekday' value='2' onChange={radioHandler} />
            <label htmlFor="Tuesday">Tuesday</label>
          </p>
          <p>
            <input type='radio' name='weekday' value='3' onChange={radioHandler} />
            <label htmlFor="Wednesday">Wednesday</label>
          </p>
          <p>
            <input type='radio' name='weekday' value='4' onChange={radioHandler} />
            <label htmlFor="Thursday">Thursday</label>
          </p>
          <p>
            <input type='radio' name='weekday' value='5' onChange={radioHandler} />
            <label htmlFor="Friday">Friday</label>
          </p>
          <p>
            <input type='radio' name='weekday' value='6' onChange={radioHandler} />
            <label htmlFor="Saturday">Saturday</label>
          </p>
        </div>
        {gameTimes.length === 0 && (
          <div>
            <h4>First Game Time</h4>
            <CaptnTextInput type='time' step='any' onChangeText={(e) => setStartTime(e)} value={startTime} />
            <h4>Last Game Time</h4>
            <CaptnTextInput type='time' step='any' onChangeText={(e) => setEndTime(e)} value={endTime} />
            <h4>Game Duration</h4>
            <CaptnTextInput type='number' step='5' onChangeText={(e) => setDuration(e)} value={duration} />
            <CaptnButton handleSubmit={calculateTimes}>Calculate Times</CaptnButton>
          </div>
        )}
        {gameTimes.length > 0 && (
          <div className="vertical text-center">
            <h4>Game Times</h4>
            {gameTimes.map(gt => (<div key={gt.format('hh:mm')}>{gt.format('hh:mm')}</div>))}
            <CaptnButton handleSubmit={() => setGameTimes([])}>Reset</CaptnButton>
          </div>
        )}
        <div>
          <h4>Bye Weeks</h4>
          {byeWeeks.map(f => (<p key={f.getDate()}>{moment(f).format('ll')}</p>))}
          <DateSelector dateTime={byeWeek} onChangeDate={(e) => setByeWeek(moment(e))} />
          <CaptnButton handleSubmit={addByeWeek}>Add Bye Week</CaptnButton>
        </div>
      </div>
      <div>
        <CaptnButton primary handleSubmit={schedule}>Schedule!</CaptnButton>
      </div>
      {Object.keys(gamesByDate).length > 0 && (
        <div className="schedule-area">
          {renderGameDates(gamesByDate)}
          <CaptnButton handleSubmit={saveNewSchedule} primary>Save All Games</CaptnButton>
        </div>
      )}
    </>
  )
}
