import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useDeleteTeamMemberByIdMutation, useUpdateTeamMemberMutation } from "../../features/teamMembersQueries";
import { TeamMember } from "../../models/TeamMember";
import CaptnButton from "../shared/CaptnButton";
import CreateTeamMember from "./Create";
import StripeElements from "../Payments/StripeElements";
import { Payment } from "../../models/Payment";

export type Props = {
  teamMember: TeamMember,
  isCaptain?: boolean,
}
export interface TeamMemberPartial {
  team_member: {
    role: string,
  }
}

export default function ShowTeamMember(props: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const teamMember = props.teamMember;
  const isCaptain = props.isCaptain;
  const [deleteTeamMember] = useDeleteTeamMemberByIdMutation();
  const [updateTeamMember] = useUpdateTeamMemberMutation();
  const handleSubmit = () => {
    deleteTeamMember(teamMember.id).then((response) => {
      HandleResponse(response, 'Team Member deleted')
    });
  }
  const toggleEditing = () => {
    setIsEditing(!isEditing)
  }
  // TODO: I don't think we ever handle the idea of updating a teamMember if a user
  // has not signed up with matching data. Look into that
  const updateMember = (teamMemberData: TeamMemberPartial) => {
    updateTeamMember({ id: props.teamMember.id, ...teamMemberData }).then((response) => {
      HandleResponse(response, 'Team Member updated')
    });
    setIsEditing(false)
  }

  // TODO: We should alert the user that they paid, but this is re-rendering multiple times
  // useEffect(() => {
  //   setPayemntAlerted(true);
  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     'redirect_status'
  //   );
  //   if (clientSecret === 'succeeded') {
  //     CreateToastAlert({content: 'Payment Succeeded!', errorLevel: 'success'});
  //   }
  // }, []);

  const renderPayments = (paymentDetails: Payment[]) => {
    const unpaidDetails = paymentDetails.filter(p => !p.userHasPaid)
    return unpaidDetails.map(paymentDetail => {
      return (
        <div key={`payment-${paymentDetail.id}`}>
          <p>You owe ${(paymentDetail.amountInCents / 100).toFixed(2)}</p>
          <StripeElements paymentDetail={paymentDetail} />
        </div>
      )
    })
  }

  const renderReadOnlyPayments = (paymentDetails: Payment[]) => {
    return paymentDetails.map(paymentDetail => {
      if (paymentDetail.userHasPaid) {
        return (
          <div key={`payment-${paymentDetail.id}`}>
            <span>Paid ${(paymentDetail.amountInCents / 100).toFixed(2)} &#9745;</span>
          </div>
        )
      }
      return (
        <div key={`payment-${paymentDetail.id}`}>
          <p>User owes ${(paymentDetail.amountInCents / 100).toFixed(2)}</p>
        </div>
      )
    })
  }

  return (
    <div>
      {/* Only show these parts when creating a NEW member */}
      {isEditing && (
        <div>
          <CreateTeamMember teamId={teamMember.teamId} teamMember={teamMember} updateMember={(teamMemberData) => updateMember(teamMemberData)} />
          <CaptnButton destructive handleSubmit={handleSubmit}>Delete</CaptnButton>
          <CaptnButton handleSubmit={toggleEditing}>Cancel</CaptnButton>
        </div>
      )}
      {!isEditing && (
        <div className='horizontal'>
          <p>{teamMember.identifier}</p>
          {isCaptain &&
            <div>
              <CaptnButton className="edit-team-member" handleSubmit={toggleEditing}>Edit</CaptnButton>
            </div>
          }
          {isCaptain && teamMember.payments?.length > 0 && (
            <>{renderReadOnlyPayments(teamMember.payments)}</>
          )}
        </div>
      )}

      {teamMember.userIsMember && teamMember.payments?.length > 0 && (
        <>
          {renderPayments(teamMember.payments)}
        </>
      )}
    </div>
  )
}
