import moment, { Moment } from "moment";
import { useParams } from "react-router-dom";
import CaptnButton from "../components/shared/CaptnButton";
import { useGetCompetitionSessionByIdQuery } from "../features/leaguesQueries";
import { selectUser } from "../features/user/userSlice";
import { useAppSelector } from "../redux/hooks";

export default function CompetitionSessionDetailsRoute() {
  let { competitionSessionId } = useParams<{ competitionSessionId: string }>();
  //TODO: There HAS to be a better way to convince typescript that we got here with a competitionSessionId, it won't be null, I promise
  competitionSessionId = !competitionSessionId ? '' : competitionSessionId;

  const { data, isLoading, isError } = useGetCompetitionSessionByIdQuery(competitionSessionId)
  const currentUser = useAppSelector(selectUser).currentUser;
  // const isSiteAdmin = currentUser && currentUser.isSiteAdmin;


  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data) return (<p>This competitionsession somehow does not exist</p>)

  const renderGames = () => {
    if (data.games && data.games.length > 0) {
      return (
        <>
          {data.games.map(game => {
            const homeName = game.homeTeam ? game.homeTeam.name : 'TBD';
            const awayName = game.awayTeam ? game.awayTeam.name : 'TBD';
            return (
              <div className="card" key={game.id}>
                <>
                  {homeName} vs {awayName}<hr />
                  <p>{moment(game.gameDateTime).format('LT')} - {game.field}</p>
                </>
              </div>)
          })}
        </>
      )
    }
    return <p>{data.leagueSession.startDate.toString()}</p>
  }

  const renderTeams = () => {
    if (data.teams && data.teams.length > 0) {
      return (
        <>
          {data.teams.map(team => { return (<p key={`team-${team.id}`}><CaptnButton link={`/teams/${team.id}`}>{team.name}</CaptnButton></p>) })}
        </>

      )
    }
    return <p>No teams signed up yet.</p>
  }

  return (
    <>
      <p>Available Sessions</p>
      {renderGames()}
      <p>Will eventually show the schedule</p>

      <CaptnButton link='/'>Home</CaptnButton>


      {/* (isSiteAdmin || data.isCompetitionSessionAdmin) */}
      {data.isLeagueAdmin && (
        <>
          {renderTeams()}
          {/* <p>Add a session</p> */}
          {/* <p className="small text-center">This is something that will change throughout the year. Something like seasons, Fall/Summer/One off Tournament, competition level is next.</p> */}
          {/* <CreateCompetitionSessionSession competitionsession={data} /> */}
        </>
      )}
    </>
  )
}