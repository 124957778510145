import { useEffect, useState } from 'react';
import {loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import { Payment } from '../../models/Payment';

type Props = {
  paymentDetail: Payment
}

export default function StripeElements(props: Props) {
  const { paymentDetail } = props;
  const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY ? process.env.REACT_APP_STRIPE_PUBLIC_KEY : '';
  const stripePromise = loadStripe(stripePublicKey, {
    stripeAccount: paymentDetail.stripeAccountId
  });
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session/${paymentDetail.id}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  });

  const options = {
    clientSecret,
    theme: "stripe",
  };

  return (
    <div className="StripeElement">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};

