import { useParams } from "react-router-dom";
import CreateLeague from "../components/Leagues/Create";
import CreateAdmin from "../components/Locations/CreateAdmin";
import CaptnButton from "../components/shared/CaptnButton";
import CardDetails from "../components/shared/CardDetails";
import { useGetLocationByIdQuery } from "../features/locationsQueries";
import { selectUser } from "../features/user/userSlice";
import { useAppSelector } from "../redux/hooks";

export default function LocationDetailsRoute() {
  let { locationId } = useParams<{ locationId: string }>();
  //TODO: There HAS to be a better way to convince typescript that we got here with a locationId, it won't be null, I promise
  locationId = !locationId ? '' : locationId;

  const { data, isLoading, isError } = useGetLocationByIdQuery(locationId)
  const currentUser = useAppSelector(selectUser).currentUser;
  const isSiteAdmin = currentUser && currentUser.isSiteAdmin;


  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data) return (<p>This location somehow does not exist</p>)
  
  const renderLocationAdmins = () => {
    if (data.locationAdmins) {
      return (
        <ul>
          {data.locationAdmins.map(la => { return (<li key={`la-${la.id}`}>{la.firstName} {la.lastName} - {la.email}</li>) } )}
        </ul>
      )
    }
    return <p>No admins for this location</p>
  }

  const renderLeagues = () => {
    if (data.league) {
      return data.league.map(la => { return (<CardDetails header={la.description} link={`/league/${la.id}`} /> )})
    }
    return <p>No leagues at this time</p>
  }

  return (
    <>
      <p>{data.name}</p>
      <p>{data.address}</p>
      <p>{data.city}</p>
      <p>{data.state}</p>
      <p>{data.zip}</p>
      {/* <p>{data.country}</p> */}
      <p>{data.phoneNumber}</p>
      {/* stripeAccountId */}

      <p>Available Leagues</p>
      {renderLeagues()}

      <CaptnButton link='/'>Home</CaptnButton>


      { (isSiteAdmin || data.isLocationAdmin) && (
        <>
          <p>Current Admins</p>
          {renderLocationAdmins()}
          <CreateAdmin location={data} />
          <p>Add a league</p>
          <p className="small text-center">This is something that won't change, like "Wednesday Coed". Skill levels and season descriptors will be in the next section.</p>
          <CreateLeague location={data} />
        </>
      )}
    </>
  )
}