import CreateLocation from "../components/Locations/Create";
import ShowAllLocations from "../components/Locations/ShowAll";
import CaptnButton from "../components/shared/CaptnButton";
import { selectUser } from "../features/user/userSlice";
import { useAppSelector } from "../redux/hooks";

export default function LocationsRoute() {
  const currentUser = useAppSelector(selectUser).currentUser;
  const isSiteAdmin = currentUser && currentUser.isSiteAdmin;

  return (
    <>
      <ShowAllLocations />

      <CaptnButton link='/'>Home</CaptnButton>
      { isSiteAdmin && (
        <CreateLocation />
      )}
    </>
  )
}