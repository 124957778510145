import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../redux/store';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const userInfo = (getState() as RootState).user;
    // Todo: H
    if (userInfo && userInfo.authInfo && userInfo.authInfo.token !== '') {
      // If we have a token set in state, let's assume that we should be passing it.
      headers.set('Authorization', `Token token="${userInfo.authInfo.token}", email="${userInfo.authInfo.email}"`)
    }
    return headers
  },
})