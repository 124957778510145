import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewTeamMutation } from "../../features/teamsQueries";
import CaptnButton from "../shared/CaptnButton";
import CaptnPicker from "../shared/CaptnPicker";
import CaptnTextInput from "../shared/CaptnTextInput";

export default function CreateTeam() {
  const [name, setName] = useState('');
  const [coed, setCoed] = useState('combined');
  const [teamColor, setTeamColor] = useState('');
  const [createTeam] = useAddNewTeamMutation();
  const handleSubmit = () => {
    const teamData = {
      team: {
        name,
        coed,
        team_color: teamColor
      }
    }
    createTeam(teamData).then((response) => {
      HandleResponse(response, 'Team created!');
    });
    setName('')
    setTeamColor('')
  }

  const genderOptions = {
    'Male': 'male',
    'Female': 'female',
    'Combined': 'combined',
  }

  return (
    <div className="vertical">
      <CaptnTextInput onChangeText={(newName) => { setName(newName) }} value={name} placeholder="Name" />
      <CaptnTextInput onChangeText={(newColor) => { setTeamColor(newColor) }} value={teamColor} placeholder="Color" />
      <CaptnPicker options={genderOptions} value={coed} handleChange={(itemValue) => setCoed(itemValue)} />
      <CaptnButton primary handleSubmit={handleSubmit}>Create Team</CaptnButton>
    </div>
  )
}

// Type '{ team: { name: string; coed: number; team_color: string; }; }' has no properties in common with type 
// 'UseMutationStateOptions<MutationDefinition<{ team: { name: string; color: string; gender: string; }; }, BaseQueryFn
