import { useGetAllGamesQuery } from '../../features/gamesQueries';
import { Game } from '../../models/Game';
import ShowGame from './Show';

export default function ShowAllGames() {
  const { data, isLoading, isError } = useGetAllGamesQuery()

  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data || !data.length) return (<p>You do not have any games in the next week!</p>)

  const renderGameCard = (game: Game) => {
    return (
      <ShowGame key={game.id} game={game} showTeamName subs={game.subs} />
    )
  }
  return (
    <>
      <p>Upcoming Games</p>
      {data.map(game => {
        return renderGameCard(game)
      })}
    </>
  )
}
