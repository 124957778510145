import React from 'react';
import { useGetAllTeamsQuery } from '../../features/teamsQueries';
import ShowTeamDetails from './Details';
import CaptnButton from '../shared/CaptnButton';

export default function ShowAllTeams() {
  const { data, isLoading, isError } = useGetAllTeamsQuery()

  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data || !data.length) return (
    <div>
      <CaptnButton link='/about'>Click here to see how Captn works</CaptnButton>
      <p>You are not part of any teams</p>
    </div>
  )

  return (
    <div>
      {data.map(team => {
        return <ShowTeamDetails key={team.id} team={team} />
      })}
    </div>
  )
}
