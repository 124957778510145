import { storeData } from '../features/localStorage';
import { useGetUserByIdQuery } from '../features/user/userQuery';
import { removeAuthFromStorage, selectUser } from '../features/user/userSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import ShowAllGames from './Games/ShowAll';
import CaptnButton from './shared/CaptnButton';

export default function HomeScreen() {
  const authInfo = useAppSelector(selectUser).authInfo;
  if (!authInfo.userId) {
    const loginUrl = window.location.href + 'login';
    window.location.href = loginUrl;
  }
  const { data, isLoading, isError } = useGetUserByIdQuery(authInfo.userId);
  const dispatch = useAppDispatch();

  // TODO: Add logout button
  const logout = () => {
    dispatch(removeAuthFromStorage());
    window.location.href = window.location.origin;
  }

  if (isError) return (
    <div>Error</div>
  )

  if (isLoading) return <p>Loading</p>

  if (!data) {
    return <p>Empty Query</p>
  }
  storeData('currentUser', JSON.stringify(data));

  return (
    <div className="container">
      {/* <Counter /> */}
      <ShowAllGames />
        <div className='horizontal'>
          <CaptnButton link='teams'>Teams</CaptnButton>
          <CaptnButton link='profile'>Profile</CaptnButton>
          <CaptnButton handleSubmit={logout}>Logout</CaptnButton>
        </div>
      {data.isSiteAdmin && (
        <div className='horizontal'>
          <CaptnButton link='locations'>Locations</CaptnButton>
        </div>
      )}
    </div>
  )
}