import React from 'react';



export type Props = {
  value: string | number,
  placeholder?: string,
  autoComplete?: string, // TODO? Ensure autoComplete is something that is supported
  icon?: string, // TODO? Ability to put an icon somewhere in the box
  type?: string,
  decimal?: boolean,
  label?: string,
  step?: string,
  textarea?: boolean,
  onChangeText: (text: string) => void
}

export default function CaptnTextInput(props: Props) {
  const step = props.step ? props.step : props.decimal ? 0.01 : 1;
  const onChangeText = (text: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChangeText(text.target.value)
  }

  if (props.textarea) {
    return (
      <textarea
        className='captnTextArea'
        placeholder={props.placeholder}
        onChange={text => onChangeText(text)}
        value={props.value}
      />
    )
  }
  return (
    <>
      {props.label && (
        <label>{props.label}</label>
      )}
      <input
        className='captnTextInput'
        type={props.type}
        placeholder={props.placeholder}
        step={step}
        onChange={text => onChangeText(text)}
        value={props.value}
      />
    </>
  )
}
