import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewTeamMemberMutation } from "../../features/teamMembersQueries";
import { TeamMember } from "../../models/TeamMember";
import CaptnButton from "../shared/CaptnButton";
import CaptnPicker from "../shared/CaptnPicker";
import CaptnTextInput from "../shared/CaptnTextInput";

export type Props = {
  teamId: string | number,
  teamMember?: TeamMember,
  updateMember?: (data: TeamMemberPartial) => void
}

export interface TeamMemberPartial {
  team_member: {
    role: string,
    team_id?: number | string,
    email?: string,
    phone_number?: string
  }
}

export default function CreateTeamMember(props: Props) {
  // Getting lazy to appease typescripte here. I need a proper way to promise typescript
  // that I'm not going to pass a null value in to my pickers/components. Maybe I should
  // just handle that type of stuff on the component itself... TODO
  let initialPhoneNumber = props.teamMember == null ? '' : props.teamMember.phoneNumber;
  initialPhoneNumber = initialPhoneNumber == null ? '' : initialPhoneNumber;
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);
  let initialEmail = props.teamMember == null ? '' : props.teamMember.email;
  initialEmail = initialEmail == null ? '' : initialEmail;
  const [email, setEmail] = useState(initialEmail);
  const initialRole = props.teamMember == null ? 'player' : props.teamMember.role;
  const [role, setRole] = useState(initialRole);
  const buttonText = props.teamMember == null ? 'Invite Team Member' : 'Update';
  const [createTeamMember] = useAddNewTeamMemberMutation();
  const playerTypeOptions = {
    'Player': 'player',
    'Captain': 'captain',
    'Sub': 'sub',
  }
  const handleSubmit = () => {
    if (props.teamMember == null) {
      const teamMemberData: TeamMemberPartial = {
        team_member: {
          role,
          email,
          team_id: props.teamId,
          phone_number: phoneNumber
        }
      }

      // TODO: Probably a better way to this
      if (!email) {
        delete teamMemberData['team_member']['email']
      }
      if (!phoneNumber) {
        delete teamMemberData['team_member']['phone_number']
      }

      createTeamMember(teamMemberData).then((response) => {
        HandleResponse(response, 'Team Member created!')
      });

      setEmail('');
      setPhoneNumber('');
    } else {
      const teamMemberData: TeamMemberPartial = {
        team_member: {
          role
        }
      }
      // Maybe we handle this on the TeamMemberShow page instead. TBD
      if (props.updateMember != null) {
        props.updateMember(teamMemberData);
      }
    }
  }
  return (
    <div className="vertical">
      {props.teamMember == null && (
        <div className="vertical">
          <p>Enter Phone Number, Email, or both</p>
          <CaptnTextInput placeholder="Phone Number" onChangeText={(newPhoneNumber) => (setPhoneNumber(newPhoneNumber))} value={phoneNumber} />
          <CaptnTextInput placeholder="Email" onChangeText={(newEmail) => (setEmail(newEmail))} value={email} />
        </div>
      )}
      <CaptnPicker options={playerTypeOptions} handleChange={(itemValue) => setRole(itemValue)} value={role} />
      <CaptnButton primary handleSubmit={handleSubmit}>{`${buttonText}`}</CaptnButton>
    </div>
  )
}
