import { useUpdateGameAttendeeMutation } from "../../features/gamesQueries";
import { GameAttendee } from "../../models/GameAttendee";
import CaptnPicker from "../shared/CaptnPicker";

export type Props = {
  gameAttendee: GameAttendee
}

export default function ShowGameAttendee(props: Props) {
  const attendanceMap: { [key: string]: boolean | null } = {
    'true': true,
    'false': false,
    'null': null,
  };
  const gameAttendee = props.gameAttendee;
  // I'm sorry. I'll work on this... Picker doesn't allow null as a selected value, so I have to convert it to a string
  const attendanceString = gameAttendee.attending === true ? 'true' : gameAttendee.attending === false ? 'false' : 'null';
  const [updateGameAttendee] = useUpdateGameAttendeeMutation();
  const handleSubmit = ({ attendingString }: { attendingString: string }) => {
    // Then I have to convert it back to a nullable bool in the request
    const attending = attendanceMap[attendingString]
    updateGameAttendee({ id: gameAttendee.id, attending: attending })
  }

  const options = {
    'Attending': 'true',
    'Not Attending': 'false',
    'Unknown': 'null',
  }

  return (
    <div className="card horizontal">
      <p>{gameAttendee.teamMember.identifier}</p>
      <CaptnPicker options={options} value={attendanceString} handleChange={(itemValue) => handleSubmit({ attendingString: itemValue })} />
    </div >
  )
}
