import { Team } from "../../models/Team";
import CardDetails from "../shared/CardDetails";

type TeamDetailProps = {
  team: Team;
};

export default function ShowTeamDetails({ team }: TeamDetailProps) {
  return (
    <CardDetails header={team.name} link={team.id} />
  )
}
