import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewGameAttendeeMutation } from "../../features/gamesQueries";
import { TeamMember } from "../../models/TeamMember";
import CaptnButton from "../shared/CaptnButton";

export type Props = {
  gameId: number
  teamMember: TeamMember
}
export interface GameAttendeePartial {
  game_attendee: {
    team_member_id: number,
    game_id: number,
    teamMemberId?: number // I don't know why I need this, but apparently I do to make typescript happy
  }
}

export default function CreateGameAttendee(props: Props) {
  const teamMember = props.teamMember;
  const gameId = props.gameId;
  const [createGameAttendee] = useAddNewGameAttendeeMutation();
  const handleSubmit = () => {
    const gameAttendeeData: GameAttendeePartial = {
      game_attendee: {
        team_member_id: teamMember.id,
        game_id: gameId,
      }
    }

    createGameAttendee(gameAttendeeData).then((response) => {
      HandleResponse(response, 'Player invited');
    });
  }

  return (
    <div className="card horizontal">
      <p>{teamMember.identifier}</p>
      <CaptnButton primary handleSubmit={handleSubmit}>Invite</CaptnButton>
    </div>
  )
}
