import moment from "moment";
import { useState } from "react";
import { useParams } from "react-router-dom";
import CreateGameAttendee from "../components/GameAttendees/Create";
import ShowGameAttendee from "../components/GameAttendees/Show";
import CreateGame from "../components/Games/Create";
import CaptnButton from "../components/shared/CaptnButton";
import CaptnMessage, { Recipient } from "../components/shared/CaptnMessage";
import { HandleResponse } from "../features/emptySplitApi";
import { useGetGameByIdQuery } from "../features/gamesQueries";
import { useAddNewMessageMutation } from "../features/teamsQueries";
import { GameAttendee } from "../models/GameAttendee";
import { Message } from "../models/Message";
import { useNavigate} from "react-router-dom";

type QuickMessageOption = { [key: string]: Recipient[] }

export default function GameDetailsRoute() {
  const [isEditing, setIsEditing] = useState(false);
  let { gameId } = useParams<{ gameId: string }>();
  const [sendingMessage, setSendingMessage] = useState(false);
  const [attendanceView, setAttendanceView] = useState('attending');
  //TODO: There HAS to be a better way to convince typescript that we got here with a gameId, it won't be null, I promise
  gameId = !gameId ? '' : gameId;

  const { data, isLoading, isError } = useGetGameByIdQuery(gameId)
  const [newMessage] = useAddNewMessageMutation();
  let navigate = useNavigate();

  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data) return (<p>You are not part of any teams</p>)

  // TODO: Handle home/away
  // const team = data.homeTeam;
  let quickMessageOptions = [] as QuickMessageOption[];
  const currentUserIsCaptn = data.homeTeam.userIsCaptain
  const allTeamMembers = data.gameAttendee.map((ga) => ga.teamMember)
  quickMessageOptions.push({ 'Everyone': allTeamMembers });
  const attendingPlayers = data.gameAttendee.filter(attendee => { return attendee.attending === true })
  const attendingTeamMembers = attendingPlayers.map((ga) => ga.teamMember)
  quickMessageOptions.push({ 'Attending': attendingTeamMembers });
  const attendingCount = attendingPlayers.length
  const notAttendingPlayers = data.gameAttendee.filter(attendee => { return attendee.attending === false })
  const notAttendingCount = notAttendingPlayers.length
  const notRespondedPlayers = data.gameAttendee.filter(attendee => { return attendee.attending === null })
  const notRespondedTeamMembers = notRespondedPlayers.map((ga) => ga.teamMember)
  quickMessageOptions.push({ 'Not Responded': notRespondedTeamMembers });
  const notRespondedCount = notRespondedPlayers.length
  const selfGameAttendee = data.gameAttendee.find(attendee => { return attendee.userIsMember })

  const toggleIsEditing = () => {
    setIsEditing(!isEditing)
  }
  const sendMessage = (message: Message) => {
    newMessage({ message }).then((response) => {
      HandleResponse(response, 'Message sent');
    });
  }

  const renderGameAttendees = ((players: GameAttendee[]) => {
    if (players.length == 0) {
      return <p>N/A</p>
    }
    return players.map(player => {
      if (currentUserIsCaptn) {
        return <ShowGameAttendee key={player.id} gameAttendee={player} />
      } else {
        return <div className="card" key={player.id}>{player.teamMember.identifier}</div>
      }
    })
  })
  const renderGameAttendeesGender = ((players: GameAttendee[]) => {
    let male = players.filter((p) => p.gender === 'male').length;
    let female = players.filter((p) => p.gender === 'female').length;

    return <p>Attendance Count: Women: {female}, Men: {male}</p>
  })
  const title = `${data.location} - ${moment(data.gameDateTime).format('L, h:mm a')}`

  const identifier = (id: number) => {
    const teamMember = allTeamMembers.find((teamMember) => teamMember.id === id);
    const name = teamMember ? teamMember.identifier : '';
    return name
  }

  if (isEditing) {
    return (
      <>
        <CreateGame game={data} team={data.homeTeam} stopEditing={toggleIsEditing} />
        <CaptnButton handleSubmit={toggleIsEditing}>Back</CaptnButton>
      </>
    )
  }

  // If a game has not been passed in we're viewing, just return all the good stuff
  return (
    <div>
      <h2>{title}</h2>
      {renderGameAttendeesGender(attendingPlayers)}
      <div className="horizontal">
        <CaptnButton handleSubmit={() => setAttendanceView('attending')}><i>Attending ({attendingCount})</i></CaptnButton>
        <CaptnButton handleSubmit={() => setAttendanceView('not-attending')}><i>Not Atteending ({notAttendingCount})</i></CaptnButton>
        <CaptnButton handleSubmit={() => setAttendanceView('not-responded')}><i>Not Responded ({notRespondedCount})</i></CaptnButton>
      </div>
      <hr />
      {attendanceView == 'attending' && (
        <div>
          <h4>Attending: {attendingCount} </h4>
          {renderGameAttendees(attendingPlayers)}
        </div>
      )}
      {attendanceView == 'not-attending' && (
        <div>
          <h4>Not Attending: {notAttendingCount} </h4>
          {renderGameAttendees(notAttendingPlayers)}
        </div>
      )}
      {attendanceView == 'not-responded' && (
        <div>
          <h4>Has Not Responded: {notRespondedCount} </h4>
          {renderGameAttendees(notRespondedPlayers)}
        </div>
      )}
      {selfGameAttendee && (
        <div>
          <h4>Set your attendance:</h4>
          <ShowGameAttendee gameAttendee={selfGameAttendee} />
        </div>
      )}

      {currentUserIsCaptn && (
        <div>
          <p>Subs</p>
          {data.subs && data.subs.map(sub => {
            return <CreateGameAttendee key={sub.id} teamMember={sub} gameId={data.id} />
          })}
          <div>
            <CaptnButton handleSubmit={toggleIsEditing}>Edit Game</CaptnButton>
          </div>
          <div>
            {!sendingMessage && (
              <CaptnButton primary handleSubmit={() => setSendingMessage(true)}>Send Message</CaptnButton>
            )}
            {sendingMessage && (
              <>
                <CaptnMessage
                  availableUsers={allTeamMembers}
                  identifier={(id) => { return identifier(id) }}
                  handleSubmit={(message: Message) => sendMessage(message)}
                  quickMessageOptions={quickMessageOptions}
                />
              </>
            )}
          </div>
        </div>

      )}
      <div className="horizontal">
        <CaptnButton link='/'>Home</CaptnButton>
        <CaptnButton link='/teams'>Teams</CaptnButton>
        <CaptnButton handleSubmit={() => navigate(-1)} >Back</CaptnButton>
      </div>
    </div>
  )
}
