import React from 'react';



export type Props = {
  value: boolean,
  icon?: string, // TODO? Ability to put an icon somewhere in the box
  label?: string,
  onChangeText: (text: boolean) => void
}

export default function CaptnCheckbox(props: Props) {
  const onChangeText = (text: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeText(text.target.checked)
  }

  return (
    <div className='horizontal'>
      {props.label && (
        <label>{props.label}</label>
      )}
      <input
        className='captnCheckbox'
        type='checkbox'
        onChange={text => onChangeText(text)}
        checked={props.value}
      />
    </div>
  )
}
