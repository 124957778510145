import CaptnButton from './CaptnButton';

export type Props = {
  header: string,
  link: string | number,
  className?: string,
}

export default function CardDetails(props: Props) {
  const { className, header, link } = props;

  return (
    <div key={`card-${link}`} className={`card card-details ${className}`}>
      <div className='header'>{header}</div>
      <CaptnButton link={link.toString()}>Details</CaptnButton>
    </div>
  )
}