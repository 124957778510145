import { useState } from 'react';
import CaptnTextInput from '../shared/CaptnTextInput';
import CaptnPicker from '../shared/CaptnPicker';
import CaptnButton from '../shared/CaptnButton';
import { useAddNewUserMutation } from '../../features/user/userQuery';
import { HandleResponse } from '../../features/emptySplitApi';
import CaptnCheckbox from '../shared/CaptnCheckbox';

export default function CreateForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('male');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [receivePhoneNotification, setReceivePhoneNotification] = useState(false);
  const [receiveEmailNotification, setReceiveEmailNotification] = useState(false);

  const [addUser] = useAddNewUserMutation();

  const createAccount = () => {
    const userData = {
      user: {
        email,
        password,
        gender,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        password_confirmation: passwordConfirmation,
        receive_phone_notification: receivePhoneNotification,
        receive_email_notification: receiveEmailNotification,
      }
    }
    addUser(userData).then((response) => {
      HandleResponse(response, 'User created');
      if ('data' in response){
        const auth = { 'token': response.data.rememberDigest, 'email': response.data.email, 'userId': response.data.id }
        window.localStorage.setItem('authInfo', JSON.stringify(auth));
        window.location.href = window.location.origin
      }
    });
  }

  const options = {
    'Male': "male",
    'Female': "female",
  }

  return (
    <>
      <CaptnTextInput placeholder="First Name" autoComplete='name' onChangeText={(newVal) => (setFirstName(newVal))} value={firstName} />
      <CaptnTextInput placeholder="Last Name" autoComplete='name' onChangeText={(newVal) => (setLastName(newVal))} value={lastName} />
      <CaptnTextInput placeholder="Email" type='email' onChangeText={(newVal) => (setEmail(newVal))} value={email} />
      <CaptnTextInput placeholder="Phone Number" type='tel' onChangeText={(newVal) => (setPhoneNumber(newVal))} value={phoneNumber} />
      <CaptnPicker options={options} value={gender} handleChange={(itemValue) => setGender(itemValue)} />
      <CaptnCheckbox label="Text Notifcations" onChangeText={(val) => setReceivePhoneNotification(val)} value={receivePhoneNotification} />
      <CaptnCheckbox label="Email Notifcations" onChangeText={(val) => setReceiveEmailNotification(val)} value={receiveEmailNotification} />
      <CaptnTextInput placeholder="Password" type='password' autoComplete='password' onChangeText={(newVal) => (setPassword(newVal))} value={password} />
      <CaptnTextInput placeholder="Confirm" type='password' autoComplete='password' onChangeText={(newVal) => (setPasswordConfirmation(newVal))} value={passwordConfirmation} />
      <CaptnButton primary={true} handleSubmit={createAccount}>Create Account</CaptnButton>
    </>
  );
}