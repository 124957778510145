import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewLocationAdminMutation } from "../../features/locationsQueries";
import { Location } from "../../models/Location";
import CaptnButton from "../shared/CaptnButton";
import CaptnTextInput from "../shared/CaptnTextInput";

type CreateAdminProps = {
  location: Location;
};

export default function CreateAdmin({ location }: CreateAdminProps) {
  const [email, setEmail] = useState('');
  const [createLocationAdmin] = useAddNewLocationAdminMutation();
  const createAdmin = () => {
    const adminData = {
      location_admin: {
        location_id: location.id,
        email,
      }
    }
    createLocationAdmin(adminData).then((response) => {
      HandleResponse(response, 'Admin invited!');
    });

  }
  return (
    <>
      <h3>Invite Admin</h3>
      <CaptnTextInput onChangeText={(n) => { setEmail(n)}} value={email} placeholder="email" />
      <CaptnButton handleSubmit={createAdmin} primary>Add</CaptnButton>
    </>
  )
}
