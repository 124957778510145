import { useState } from "react";
import { useParams } from "react-router-dom";
import CreateCompetitionSession from "../components/CompetitionSessions/Create";
import CreateSchedule from "../components/Schedules/Create";
import CaptnButton from "../components/shared/CaptnButton";
import CaptnPicker from "../components/shared/CaptnPicker";
import CreateToastAlert from "../components/shared/toast";
import { HandleResponse } from "../features/emptySplitApi";
import { useAddNewTeamCompetitionSessionMutation, useGetLeagueSessionByIdQuery } from "../features/leaguesQueries";
import { selectUser } from "../features/user/userSlice";
import { TeamCompetitionSessionPartial } from "../models/TeamCompetitionSession";
import { useAppSelector } from "../redux/hooks";

export default function LeagueSessionDetailsRoute() {
  const [selectedCompLevel, setSelectedCompLevel] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [createTeamCompetitionSession] = useAddNewTeamCompetitionSessionMutation();
  let { leagueSessionId } = useParams<{ leagueSessionId: string }>();
  //TODO: There HAS to be a better way to convince typescript that we got here with a leagueSessionId, it won't be null, I promise
  leagueSessionId = !leagueSessionId ? '' : leagueSessionId;

  const { data, isLoading, isError } = useGetLeagueSessionByIdQuery(leagueSessionId)
  const currentUser = useAppSelector(selectUser).currentUser;
  // const isSiteAdmin = currentUser && currentUser.isSiteAdmin;

  const [createSessionActive, setCreateSessionActive] = useState(false);
  const [viewSessionDetails, setViewSessionDetails] = useState(false);


  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data) return (<p>This leagueSession somehow does not exist</p>)

  const renderSessions = () => {
    if (data.competitionSessions && data.competitionSessions.length > 0) {
      // setSelectedCompLevel(data.competitionSessions[0])
      let options: { [key: string]: string } = {};
      options['Select a league'] = '';
      data.competitionSessions.forEach(competitionSession => {
        const keyDescription = `${competitionSession.competitionLevel} (${competitionSession.remainingSpots} spots remaining)`;
        options[keyDescription] = competitionSession.id.toString();
      });

      return (
        <CaptnPicker options={options} value={selectedCompLevel} handleChange={setSelectedCompLevel} />
      )
    }

    return <p>No sessions at this time</p>
  }

  const renderSessionDetailsLinks = () => {
    if (data.competitionSessions && data.competitionSessions.length > 0) {
      return (
        <>
          {data.competitionSessions.map(ca => { return (<p key={`comp-level-${ca.id}`}><CaptnButton link={`/competition-session/${ca.id}`}>{ca.competitionLevel}</CaptnButton></p>) } )}
        </>
      )
    }

    return <p>No sessions at this time</p>
  }

  const renderAvailableTeams = () => {
    if (currentUser && currentUser.teamCaptain?.length > 0) {
      // setSelectedTeam(currentUser.teamCaptain[0].id.toString());

      let options: { [key: string]: string } = {};
      options['Select a team'] = '';
      currentUser.teamCaptain.forEach(team => {
        // iff team is not already in league
        options[team.name] = team.id.toString();
      });

      return (
        <CaptnPicker options={options} value={selectedTeam} handleChange={setSelectedTeam} />
      )
    }
    return <p>Ask your captain</p>
  }

  const handleSubmit = () => {
    if (selectedCompLevel && selectedTeam) {
      const team_competition_session : TeamCompetitionSessionPartial = {
        competition_session_id: selectedCompLevel,
        team_id: selectedTeam
      }
      createTeamCompetitionSession({team_competition_session}).then((response) => {
        HandleResponse(response, 'League Joined!');
      });
    } else {
      CreateToastAlert({content: 'Please select a league and a team', errorLevel: "error"})
    }
  }

  return (
    <>
      <p>Available</p>
      {renderSessions()}
      {renderAvailableTeams()}
      <CaptnButton primary handleSubmit={handleSubmit}>Join League</CaptnButton>

      {renderSessionDetailsLinks()}
      <CaptnButton link='/'>Home</CaptnButton>


      {/* (isSiteAdmin || data.isLeagueSessionAdmin) */}
      { data.isLeagueAdmin && createSessionActive && (
          <>
            <CaptnButton handleSubmit={() => setCreateSessionActive(false)}>Stop adding</CaptnButton>
            <p>Add a session</p>
            <p className="small text-center">Competition levels. A/B/C, Gold/Silver/Bronze. Next session you will be able to copy the description and teams over, or start from scratch.</p>
            <CreateCompetitionSession leagueSession={data} />
          </>
        )
      }
      { data.isLeagueAdmin && !createSessionActive && (
          <CaptnButton handleSubmit={() => setCreateSessionActive(true)}>Add session</CaptnButton>
        )
      }
      { data.isLeagueAdmin && data.games.length === 0 && !viewSessionDetails && (
          <CaptnButton primary handleSubmit={() => setViewSessionDetails(true)}>Create Schedule</CaptnButton>
        )
      }
      { viewSessionDetails && (
        <>
          <CaptnButton handleSubmit={() => setViewSessionDetails(false)}>Close details</CaptnButton>
          <CreateSchedule leagueSession={data} />
        </>
      )}
    </>
  )
}