import React from 'react';
import { useGetAllLocationsQuery } from '../../features/locationsQueries';
import ShowLocationDetails from './Details';

export default function ShowAllLocations() {
  const { data, isLoading, isError } = useGetAllLocationsQuery()

  if (isError) return <p>An error has occurred!</p>

  if (isLoading) return <p>Loading</p>

  if (!data || !data.length) return (<p>No locations available</p>)

  return (
    <div>
      {data.map(location => {
        return <ShowLocationDetails key={location.id} location={location} />
      })}
    </div>
  )
}
