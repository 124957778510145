import CaptnButton from "../components/shared/CaptnButton";
export default function AboutRoute() {
    return (
      <>
        <h1>Captn</h1>
        <p>Make being a captain as easy as being a player.</p>
        {/* <p>Are you a league looking for scheduling software? Click here</p> */}
        <ul>
            <li>Create a team <br /> <img src={require('../images/CreateTeam.png')} alt="" /> </li>
            <li>
                Add players by email, or phone number
                <br />
                <img src={require('../images/AddPlayer.png')} alt="" />
                <ul>
                    <li>Player: Gets added to every game, can only update their own status</li>
                    <li>Sub: Must be invited manually per game</li>
                    <li>Captain: A player that can update the status for other players, and edit the team schedule/roster/etc</li>
                </ul>
            </li>
            <li>Add games
                <ul>
                    <li>All team members will get an alert exactly 4 days before the game. If the game is Sunday at 5pm, the alert will get sent on Wednesday at 5pm</li>
                    <li>Team captains will get a roster update 3 days before the game, messages to the team can be sent from the game details page</li>
                </ul>
            </li>
        </ul>
        <p>That's it! Your team is now semi-automated. When you visit the team details page you can see how is in or out, and invite other players with the click of a button</p>
        <img src={require('../images/GameDetails.png')} alt="" />
        <p>If you have any issues or request, please utilize the <a href="/contact">contact</a> page</p>
        <div className="horizontal">
            <CaptnButton link='/'>Home</CaptnButton>
            <CaptnButton link='/teams'>Teams</CaptnButton>
        </div>
        {/* <CaptnTextInput placeholder="Enter your message here" textarea value={currentMessage} onChangeText={(msg) => setCurrentMessage(msg)} />
        <CaptnTextInput placeholder="Email" type='email' value={userEmail} onChangeText={(email) => setUserEmail(email)} />
        <CaptnButton primary handleSubmit={sendMessage}>Send</CaptnButton> */}
      </>
    )

}