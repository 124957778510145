import CaptnButton from "../components/shared/CaptnButton";
import UpdateForm from "../components/Users/Update";
import { useGetUserByIdQuery } from "../features/user/userQuery";
import { selectUser } from "../features/user/userSlice";
import { useAppSelector } from "../redux/hooks";

export default function ProfileRoute() {
  const authInfo = useAppSelector(selectUser).authInfo;
  const { data, isLoading } = useGetUserByIdQuery(authInfo.userId);
  
  if (isLoading) {
    return <p>Loading</p>
  }

  if (!data) {
    return <p>Error fetching data</p>
  }

  return (
    <div className="App">
      <div className="vertical">
        <UpdateForm user={data} authInfo={authInfo} />
        <CaptnButton link='/'>Home</CaptnButton>
      </div>
    </div>
  )
}