import { useState } from "react";
import { HandleResponse } from "../../features/emptySplitApi";
import { useAddNewCompetitionSessionMutation } from "../../features/leaguesQueries";
import { CompetitionSessionPartial } from "../../models/CompetitionSession";
import { LeagueSession } from "../../models/LeagueSession";
import CaptnButton from "../shared/CaptnButton";
import CaptnTextInput from "../shared/CaptnTextInput";

type CreateProps = {
  leagueSession: LeagueSession;
};

export default function CreateCompetitionSession({ leagueSession }: CreateProps) {
  const [competitionLevel, setCompetitionLevel] = useState('');
  const [createCompetitionSession] = useAddNewCompetitionSessionMutation();
  const handleSubmit = () => {
    const competitionSession: CompetitionSessionPartial = {
      league_session_id: leagueSession.id,
      competition_level: competitionLevel,
    }
    console.log(competitionSession)
    createCompetitionSession({ competition_session: competitionSession }).then((response) => {
      HandleResponse(response, 'CompetitionSession created!');
    });
    setCompetitionLevel('')
  }

  return (
    <div className="vertical">
      <p>Competition Levels for {leagueSession.description}</p>
      <CaptnTextInput onChangeText={(n) => { setCompetitionLevel(n) }} value={competitionLevel} placeholder="A/B/C" />
      <CaptnButton primary handleSubmit={handleSubmit}>Create Competiion Level</CaptnButton>
    </div>
  )
}
