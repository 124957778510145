import { useState } from "react";
import CaptnButton from "../components/shared/CaptnButton";
import CaptnTextInput from "../components/shared/CaptnTextInput";
import { useAddNewContactMessageMutation } from "../features/teamsQueries";
import { HandleResponse } from "../features/emptySplitApi";
import { Message } from "../models/Message";
import { toast } from "react-toastify";

export default function ContactRoute() {
  const [newMessage] = useAddNewContactMessageMutation();
  const [userEmail, setUserEmail] = useState('');
  const [currentMessage, setCurrentMessage] = useState('');
  const sendMessage = () => {
    if (userEmail === '') {
      toast.error('Please include an email address')
      return
    }
    const message: Message = {
      message: `${currentMessage} - ${userEmail}`,
      players: []
    };
    newMessage({ message }).then((response) => {
      HandleResponse(response, 'Message sent');
    });
      setCurrentMessage('')
  }
  return (
    <>
      <h1>Contact Us</h1>
      <p>We'd love to hear from you, please send us a message and a support staff will get back to you.</p>
      <CaptnTextInput placeholder="Enter your message here" textarea value={currentMessage} onChangeText={(msg) => setCurrentMessage(msg)} />
      <CaptnTextInput placeholder="Email" type='email' value={userEmail} onChangeText={(email) => setUserEmail(email)} />
      <CaptnButton primary handleSubmit={sendMessage}>Send</CaptnButton>
      <CaptnButton link="/">Home</CaptnButton>
    </>
  )
}