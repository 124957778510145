import React from 'react';
import { Link } from 'react-router-dom';

export type Props = {
  primary?: boolean,
  destructive?: boolean,
  disabled?: boolean,
  children?: any,
  link?: string,
  className?: string,
  handleSubmit?: () => void
}

export default function CaptnButton(props: Props) {
  const handleSubmit = () => {
    if (props.handleSubmit) {
      props.handleSubmit()
    }
  }

  let className = props.className ? props.className : '';
  className += ' button'
  if (props.link) {
    className += ' link'
  }
  if (props.primary) {
    className += ' primary'
  }
  if (props.destructive) {
    className += ' destructive'
  }

  if (props.link) {
    return (
      <Link className={className} to={props.link}>{props.children}</Link>
    )
  }
  return (
    <button className={className} disabled={props.disabled} onClick={handleSubmit}>
      {props.children}
    </button>
  )
}

// const faded = {
//   opacity: .5
// }
// const button = {
//   alignItems: 'center',
//   justifyContent: 'center',
//   alignSelf: 'center',
//   paddingVertical: 8,
//   paddingHorizontal: 25,
//   maxWidth: 200,
//   borderRadius: 15,
//   borderWidth: 1,
//   margin: 5,
// }
// const whiteBackground = {
//   backgroundColor: 'white',
//   borderColor: 'black',
// }
// const blueBackground = {
//   backgroundColor: 'blue',
//   borderColor: 'blue',
// }
// const redBackground = {
//   backgroundColor: '#be0000',
//   borderColor: '#be0000',
// }
// const blackText = {
//   fontSize: 12,
//   fontWeight: '600',
//   color: 'black',
// } as TextStyle
// const whiteText = {
//   fontSize: 12,
//   fontWeight: '600',
//   color: 'white',
// } as TextStyle
// const disabled = {
//   backgroundColor: 'grey'
// }

// const styles = StyleSheet.create({
//   primary: {
//     ...button,
//     ...blueBackground,
//     ...whiteText
//   } as ViewStyle,

//   plain: {
//     ...button,
//     ...blackText,
//     ...whiteBackground,
//   } as ViewStyle,

//   destructive: {
//     ...button,
//     ...redBackground,
//     ...whiteText,
//   } as ViewStyle,
// })
