import { useState } from 'react';
import CaptnTextInput from '../shared/CaptnTextInput';
import CaptnPicker from '../shared/CaptnPicker';
import CaptnButton from '../shared/CaptnButton';
import { User } from '../../models/User';
import { useUpdateUserMutation } from '../../features/user/userQuery';
import { HandleResponse } from '../../features/emptySplitApi';
import { storeData } from '../../features/localStorage';
import CaptnCheckbox from '../shared/CaptnCheckbox';

export type Props = {
  user: User,
  authInfo: {
    token: string,
    email: string,
    userId: string
  }
}

export default function UpdateForm(props: Props) {
  const { authInfo, user } = props;
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [gender, setGender] = useState(user.gender);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [receivePhoneNotification, setReceivePhoneNotification] = useState(user.receivePhoneNotification);
  const [receiveEmailNotification, setReceiveEmailNotification] = useState(user.receiveEmailNotification);

  const [updateUser] = useUpdateUserMutation();

  // Unsure if there's any better way to do this without fetch, but RTK doesn't seem to be my answer
  const updateAccount = () => {
    const userData = {
      user: {
        email,
        gender,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        receive_phone_notification: receivePhoneNotification,
        receive_email_notification: receiveEmailNotification,
      }
    }

    updateUser({
      id: user.id,
      ...userData
    }).then((response) => {
      HandleResponse(response, 'User updated')
      if (!('error' in response)) {
        storeData('currentUser', JSON.stringify(response));
        if (response.data.email !== authInfo.email) {
          const newInfo = {
            token: authInfo.token,
            userId: authInfo.userId,
            email: response.data.email
          }
          storeData('authInfo', JSON.stringify(newInfo))
        }
      }
    });
  }

  const options = {
    'Male': "male",
    'Female': "female",
  }

  return (
    <>
      <CaptnTextInput placeholder="First Name" autoComplete='name' onChangeText={(newVal) => (setFirstName(newVal))} value={firstName} />
      <CaptnTextInput placeholder="Last Name" autoComplete='name' onChangeText={(newVal) => (setLastName(newVal))} value={lastName} />
      <CaptnTextInput placeholder="Email" type='email' onChangeText={(newVal) => (setEmail(newVal))} value={email} />
      <CaptnTextInput placeholder="Phone Number" type='tel' onChangeText={(newVal) => (setPhoneNumber(newVal))} value={phoneNumber} />
      <CaptnPicker options={options} value={gender} handleChange={(itemValue) => setGender(itemValue)} />
      <CaptnCheckbox label="Text Notifcations" onChangeText={(val) => setReceivePhoneNotification(val)} value={receivePhoneNotification} />
      <CaptnCheckbox label="Email Notifcations" onChangeText={(val) => setReceiveEmailNotification(val)} value={receiveEmailNotification} />
      <CaptnButton primary={true} handleSubmit={updateAccount}>Update Profile</CaptnButton>
    </>
  );
}